<template lang="pug">
v-tab-item(key='detail' value='tab-1' :transition='false' :reverse-transition='false')
    div(v-if='cfItem.stage_id == "all" || cfItem.stage_id == showID || cfItem.stage_id == "common"' v-for='(cfItem,index) in process_array' )
        //- div().mt-7.mb-4.d-flex.align-center.justify-space-between
            //- 呈現自定義欄位標題
            //- h3.d-flex.align-center
            //-     .cube-icon.small.bg-purple.mr-2
            //-         v-icon(size='20' color='white') mdi-keyboard
            //-     span {{$t('CUSTOMFIELD.TITLE')}}
        //- v-row.sheet-box(v-if='cfItem.sort["1"] === undefined ').mb-4
        //-     v-col.d-flex.align-center.justify-center.pa-0( cols='12')
        //-         v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
        //-             h5.ma-0.t-black {{$t('CUSTOMFIELD.NO_DATA')}}
        v-form(v-model='valid' ref='form')
            v-row.mb-4(no-gutters)
                v-col(cols='12' md='12' v-for='(item,index) in cfItem.sort' :key="item.index" :style='(item.mandatory && item.type!="txt")? "height:61.95px;":""')
                    div(v-if='item.type=="bol" && item.mandatory' :key='item.index')
                        v-checkbox.py-0.ml-auto(v-model='item.value' dense='dense' :hide-details='true'  :rules='formRule.fieldRules' :ref='item.id+"_val"', :label=`item.label`)
                    div(v-if='item.type=="dat" && item.mandatory' :key='item.index')
                        v-menu(ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                            template(v-slot:activator="{ on, attrs }")
                                v-text-field(v-model='item.value'   :rules='formRule.fieldRules' v-bind="attrs" v-on="on" :label=`item.label` prepend-icon='mdi-tag' ,:ref='item.id+"_val"' readonly :clearable='!item.mandatory')
                            v-date-picker(v-model='item.value' @input='item.menu = false' no-title scrollable)
                    div(v-if='item.type=="dtt" && item.mandatory' :key='item.index')
                        v-menu(ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                            template(v-slot:activator="{ on, attrs }")
                                v-text-field(v-model='item.value'   :rules='formRule.fieldRules' v-bind="attrs" v-on="on" :label=`item.label` prepend-icon='mdi-tag' ,:ref='item.id+"_val"' readonly :clearable='!item.mandatory' @click='datepickerFocus(item)')
                            date-picker(v-model='item.value' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange")
                    div(v-if='item.type=="str" && item.mandatory' :key='item.index' )
                        v-text-field(v-model='item.value' :rules='formRule.fieldRules' :label=`item.label` prepend-icon='mdi-tag' ,:ref='item.id+"_val"')
                    div(v-if='item.type=="num" && item.mandatory' :key='item.index')
                        v-text-field(v-model.number='item.value' :rules='formRule.numMandatoryRule' :label=`item.label` prepend-icon='mdi-tag' ,:ref='item.id+"_val"' type='number' @input='item.value = check_num(item.value)')
                    div(v-if='item.type=="opt" && item.mandatory' :key='item.index')
                        v-autocomplete(v-model='item.value'
                            :items="item.list",
                            item-text="value",
                            item-value="key",
                            :rules='formRule.fieldRules'
                            :class=' all_isReadonly ? "remove-arrow" : ""' 
                            :menu-props='{closeOnClick:true}',
                            :label=`item.label` prepend-icon='mdi-tag',
                            :ref='item.id+"_val"')
                    div(v-if='item.type=="mlt" && item.mandatory' :key='item.index')
                        v-autocomplete(v-model='item.value'
                            :items="item.list",
                            item-text="value",
                            item-value="key",
                            :rules='formRule.optMandatoryRule'
                            :class=' all_isReadonly ? "remove-arrow" : ""' 
                            :menu-props='{closeOnClick:true}',
                            :label=`item.label` prepend-icon='mdi-tag',
                            :ref='item.id+"_val"'
                            multiple)
                    div(v-if='item.type=="tst" && item.mandatory' :key='item.index' :title='item.name')
                        v-text-field( v-model='item.name' :rules='formRule.optMandatoryRule' readonly :label=`item.label` prepend-icon='mdi-tag')
                        treeselect.pl-7.treeselect-menu-black(
                            :class='item.menu ? "show-treeselect":"hide-treeselect"'
                            :openOnFocus='true'
                            v-model='item.value'
                            :options="item.list",
                            :multiple="false"
                            :disable-branch-nodes="true"
                            placeholder=''
                            noResultsText='No data available'
                            item-text="value",
                            item-value="key", solo='solo' 
                            :rules='formRule.optMandatoryRule'
                            :ref='item.id+"_val"'
                            @input='changeTreeValue(item)'
                            @select='closeTreeMenu(item)'
                            :clearable='false'
                            appendToBody
                            @open='treeOpen(item)'
                            @close='treeClose(item)'
                        )
                            div(slot="option-label" slot-scope="{ node }" :title='node.raw.label') {{ node.raw.label }}
                            div(slot="value-label" slot-scope="{ node }") {{ node.raw.customLabel }}
                    div.pt-3.pb-1(v-if='item.type=="txt" && item.mandatory' :key='item.index' )
                        v-textarea.textarea-style(v-model='item.value' rows="2" no-resize dense='dense'  :rules='formRule.fieldRules' :label=`item.label` prepend-icon='mdi-tag' ,:ref='item.id+"_val"')
    v-dialog(v-model='errorDialog' width='360'  content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import CustomFieldDataService from "@/services/CustomFieldDataService";
    import TreeOptionsDataService from "@/services/TreeOptionsDataService";
    import errorDialog from '@/components/Dialog/errorDialog';
    import i18n from '/common/plugins/vue-i18n.js';
    import Treeselect from '@riophae/vue-treeselect';
    import DatePicker from 'vue2-datepicker';

    export default Vue.extend({
        props: {    
            DataClass :{
                type: String,
                required: true,
            }, 
            editData :{
                required: false,
            }, 
            StageTypeId :{
                required: false,
            },
            stageList :{
                type: Array,
            },
        },
        components: {
            errorDialog,
            Treeselect,
            DatePicker,
        },
        data() {
            return {
                errorDialog: false,
                errorMessage: '',
                dataValue:null,
                valid: true,    
                menu: false, 
                DataDefinition: null,
                all_DataDefinition: null,
                isReadonly: true, 
                all_isReadonly: true, 
                formRule: {
                    fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
                    numMandatoryRule: [(v) => (v === 0 || !!v) || i18n.t('RULE.RULE_R')],
                    optMandatoryRule: [(v) => (v || []).length >0 || i18n.t('RULE.RULE_R')],
                },
                oringal_data : null,
                process_array:[],
                showID:null,
                emptyData:[],
                responsedata:null,
                treeSelectData:null,
                openDatePicker:null,
            }
        },
        async created(){
            if(this.StageTypeId)
            {
                this.showID = this.StageTypeId;
            }
            await this.get_ts_list();
            await this.get_cf_detail();
            if(this.DataClass == 'Contact' || this.DataClass == 'Organization')
            {
                if(this.editData != null)
                {
                    for(let keydata in this.process_array[0].sort)
                    {
                        let key = this.process_array[0].sort[keydata].id;
                        this.process_array[0].sort[keydata].value = this.editData[key];
                        if(key.includes('tst') && this.process_array[0].sort[keydata].value != ""){
                            this.changeTreeValue(this.process_array[0].sort[keydata]);
                        }
                    }
                }
            }
            else if(this.DataClass == 'Opportunity' || this.DataClass == 'Case')
            {
                if(this.editData != null)
                {
                    let jsonEditData = JSON.parse(this.editData);
                    jsonEditData.sort((a,b) => {
                        if(a.stage_type_id == "all"){
                            return -1;
                        }
                        return a.stage_type_id-b.stage_type_id;
                    });
                    this.process_array.sort((a,b) => {
                        if(a.stage_id == "all"){
                            return -1;
                        }
                        return a.stage_id-b.stage_id;
                        
                    });
                    for(let stageindex in this.process_array)
                    {
                        for(let keyindex in this.process_array[stageindex].sort) 
                        {
                            let key = this.process_array[stageindex].sort[keyindex].id;
                            this.process_array[stageindex].sort[keyindex].value = jsonEditData[stageindex].data[key];
                            if(key.includes('tst') && this.process_array[stageindex].sort[keyindex].value != ""){
                                this.changeTreeValue(this.process_array[stageindex].sort[keyindex]);
                            }
                        }
                    }
                }
            }
        },
        methods:
        {   
            treeOpen(item){
                item.menu=true;
                if(item.value==""){
                    item.name=" ";
                }
            },
            treeClose(item){
                item.menu=false;
                if(item.name==" " && item.value=="")
                {
                    item.name="";
                }
            },
            datepickerFocus(item){
                this.openDatePicker = item;
            },
            datepickerChange(value, type){
                if (type === 'minute') {
                    this.openDatePicker.menu = false;
                }
            },
            changeTreeValue(item){
                let treeSelectList = this.treeSelectData.filter(el=>el.id==item.tree_select_id)[0].option_set;
                item.name = treeSelectList[item.value];
            },
            closeTreeMenu(item){
                item.menu = false;
            },
            async get_ts_list(){
                await TreeOptionsDataService.list().then((response)=>{
                    this.treeSelectData = response.data;
                })
            },
            check_num(num){
                if(num === "")
                {
                    return null;
                }
                else
                {
                    return num;
                }
            },
            async get_cf_detail(){
                this.DataDefinition = null;
                this.process_array = [];

                await CustomFieldDataService.getCustomFieldDetail(this.DataClass)
                .then(response => {
                    if(this.StageTypeId)
                    {
                        for(let i in response.data[0].definition)
                        {
                            if(response.data[0].definition[i].stage_type_id!='all')
                            {
                                this.DataDefinition = response.data[0].definition[i].data;
                                let stage_name = ""
                                try
                                {
                                     stage_name = this.stageList.filter(el=>el.id==response.data[0].definition[i].stage_type_id)[0].stage_type_name
                                }
                                catch
                                {
                                    // 
                                }
                                this.process_array.push({'pos':1,'def':this.DataDefinition,'sort':{},'id':stage_name,'stage_id':response.data[0].definition[i].stage_type_id});
                            }
                            if(response.data[0].definition[i].stage_type_id=='all')
                            {
                                this.all_DataDefinition = response.data[0].definition[i].data;
                                this.process_array.push({'pos':2,'def':this.all_DataDefinition,'sort':{},'id':'all','stage_id':'all'});
                            }
                        }
                        this.process_array.sort(function(a, b){
                            return b.pos - a.pos;
                        });
                    } 
                    else
                    {
                        this.DataDefinition = response.data[0].definition;
                        this.process_array.push({'def':this.DataDefinition,'sort':{},'id':'common','stage_id':'common'});
                    }
                }).catch(error => {
                    this.showErrorDialog(error);
                })
                for(let s in this.process_array)
                {
                    let def = this.process_array[s]['def'];
                    let sort = this.process_array[s]['sort'];
                    for (let i in def)
                    {
                        let sequenceNumber=String(def[i]['sequence_number']);
                        Vue.set(sort, sequenceNumber ,def[i]);
                    }
                    for (let i in def)
                    {
                        let sequenceNumber=String(def[i]['sequence_number']);
                        Vue.set(sort[sequenceNumber], 'type', i.slice(0,3));
                        Vue.set(sort[sequenceNumber], 'id', i);
                        if(i.slice(0,3) == "opt")
                        {
                            let optList = Object.entries(sort[sequenceNumber].option_set).map((arr) => ({
                                    key: arr[0],
                                    value: arr[1],
                                }));
                            Vue.set(sort[sequenceNumber], 'list', optList);
                        }  
                        if(i.slice(0,3) == "mlt")
                        {
                            let optList = Object.entries(sort[sequenceNumber].option_set).map((arr) => ({
                                    key: arr[0],
                                    value: arr[1],
                                }));
                            Vue.set(sort[sequenceNumber], 'list', optList);
                        }
                        if(i.slice(0,3) == "tst"){
                            let optList = this.treeSelectData.filter(el=>el.id == def[i].tree_select_id)[0].value;
                            optList = this.kickOutChildren(optList);
                            Vue.set(sort[sequenceNumber], 'list', optList);
                            Vue.set(sort[sequenceNumber], 'menu', false);
                            Vue.set(sort[sequenceNumber], 'name', "");
                        } 
                        if(i.slice(0,3) == "dat" || i.slice(0,3) == "dtt")
                        {
                            Vue.set(sort[sequenceNumber], 'menu', false);
                        }
                        Vue.set(sort[sequenceNumber], 'change', 0);
                        Vue.set(sort[sequenceNumber], 'value', '');
                    }
                }
                this.emptyData = JSON.parse(JSON.stringify(this.process_array))
            },
            kickOutChildren(data){
                return data.map((el) => {
                        if(el.children && el.children.length>0){
                            return {
                                ...el,
                                children: this.kickOutChildren(el.children),
                            };
                        }else{
                            delete el.children;
                        }
                        return el;
                });
            },
            validateForm(){
                if(this.DataClass == 'Contact' || this.DataClass == 'Organization')
                {
                    if(this.$refs.form[0].validate())
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }else{
                    try{
                        if(this.$refs.form[0].validate() && this.$refs.form[1].validate())
                        {
                            return true;
                        }
                        else
                        {
                            return false;
                        }
                    }catch{
                        if(this.$refs.form === undefined || this.$refs.form.length === 0)
                            return true;
                        else{
                            if(this.$refs.form[0].validate())
                            {
                                return true;
                            }
                            else
                            {
                                return false;
                            }
                        }
                    }
                }               
            },
            emitData() {
                if(this.process_array.length == 0)
                {
                    if(this.StageTypeId)
                    {
                        return [];
                    }
                    else
                    {
                        return {};
                    }
                }
                if (this.validateForm())
                {
                    let editData = {};
                    if(this.StageTypeId)
                    {
                        this.oringal_data = []
                        for(let i in this.process_array)
                        {
                                let dataChange = {};
                                for(let s in this.process_array[i].sort)
                                {
                                    if((this.process_array[i].sort[s].id).includes("bol") && this.process_array[i].sort[s].value != true)
                                    {
                                        dataChange[this.process_array[i].sort[s].id] = false;
                                    }
                                    else if((this.process_array[i].sort[s].id).includes("num") && this.process_array[i].sort[s].value == "")
                                    {
                                        dataChange[this.process_array[i].sort[s].id] = null;
                                    }
                                    else
                                    {
                                        dataChange[this.process_array[i].sort[s].id] = this.process_array[i].sort[s].value;
                                    }
                                }
                                if(Object.keys(dataChange).length > 0)
                                {
                                    this.oringal_data.push({"data":dataChange,"stage_type_id":this.process_array[i].stage_id});
                                }
                        }
                        editData = this.oringal_data;
                    }
                    else
                    {
                        let dataChange = {};
                        for(let s in this.process_array[0].sort)
                        {
                            if((this.process_array[0].sort[s].id).includes("bol") && this.process_array[0].sort[s].value != true)
                            {
                                dataChange[this.process_array[0].sort[s].id] = false;
                            }
                            else if((this.process_array[0].sort[s].id).includes("num") && this.process_array[0].sort[s].value == "")
                            {
                                dataChange[this.process_array[0].sort[s].id] = null;
                            }
                            else
                            {
                                dataChange[this.process_array[0].sort[s].id] = this.process_array[0].sort[s].value;
                            }
                        }
                        editData = dataChange;
                    }
                    this.responsedata = editData;
                }
                else
                {
                    this.responsedata = false;
                }
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },            
        },
        watch: {
            "StageTypeId": async function() {
                if(this.StageTypeId)
                {
                    let index = this.process_array.map(e => e.stage_id).indexOf(this.showID);
                    if(index != -1 && this.process_array[index].pos == 1 && this.process_array[index].stage_id == this.showID)
                    {
                        for(let s in this.process_array[index].def)
                        {
                            this.process_array[index].def[s].change = 0;
                            this.process_array[index].def[s].value = "";
                        }
                    }
                    await (this.showID = this.StageTypeId);
                }
            },
            isReadonly(val){
                this.removeMenu(val);
            },
        }
    });
</script>
<style lang="css">
.nopadding{
padding-left: 0;
}
</style>