<template lang="pug">
v-card.dealDialog.noteDialog
    v-card-title.d-flex.justify-center {{$t('ADDNOTE.TITLE_2')}}
    v-card-text
        v-form(v-model='valid' ref='form')
            vue-editor.mr-2.ml-1(ref='quillEditor' :class="'editReplyCss'" v-model='edit_noteData.content' :editorOptions='editorSettings_no_tool' validate-on-blur required="required")
            v-textarea.mt-4.textarea-style(ref='text' v-show='false' :label=`$t('ADDNOTE.CONTENT')` solo v-model='edit_noteData.content' :rules="formRule.contentRules" validate-on-blur required="required" no-resize)
    v-card-actions.px-2.py-4
        v-row
            v-col.pr-2(cols='6')
                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pl-2(cols='6')
                v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.SAVE')}}
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
	import Vue from 'vue';
    import NoteDataService from '@/services/NoteDataService';
    import i18n from '/common/plugins/vue-i18n.js';
    import OrganizationDataService from '@/services/OrganizationDataService';
    import { VueEditor } from 'vue2-editor';
    import errorDialog from '@/components/Dialog/errorDialog';

    async function getMention(searchTerm){
    let list = []
    await OrganizationDataService.getMentionOwner().then((response)=>{
            let userJson = window.localStorage.getItem('user');
            let userId = JSON.parse(userJson).userid;
            list = response.data.map(item => ({
                id :item.id,
                value : item.fullName = item.last_name + ' ' + item.first_name
            })
            )
            list.unshift({id:'all',value: i18n.t('GENERAL.ALL')})
            list = list.filter(el => el.id != userId)
            list = list.filter(el => el.value.includes(searchTerm))
        })
        return list;
    }

	export default Vue.extend({
        props: {
            noteData: {
                type: Object,
                required: true,
            },
        },
        components: {
            VueEditor,
            errorDialog,
        },
        data() {
			return {
                errorDialog: false,
                errorMessage: '',
                valid: true,
                formRule: {
                  contentRules: [
                      v => !!v || i18n.t('RULE.RULE_NOTE'),
                      v => (v && v.replace( /(<([^>]+)>)/ig, '').length <= 1000) || i18n.t('RULE.RULE_NOTE_1000'),
                    ], 
                },
                edit_noteData: JSON.parse(JSON.stringify(this.noteData)),
                mentionItems:[],
                passMentionItems:[],
                editorSettings_no_tool: {
                    modules: {
                        imageResize: true,
                        toolbar:false,
                        mention:{
                            mentionDenotationChars: ['@'],
                            offsetLeft: 4,
                            source: async function(searchTerm,renderList){
                                const matchedPeople = await getMention(searchTerm);
                                renderList(matchedPeople)
                            },
                            onSelect:(data, insertItem) => { 
                                const item = {
                                    text: `@${data.value}`,
                                    name: data.value,
                                    id: data.id,
                                }
                                insertItem(data) 
                                this.onSelectd(item)
                            },
                        },
                    },
                },
			}
		},
        methods: {
			validate() {
				this.$refs.form.validate()
			},
            validateForm(){
                if(this.$refs.form.validate())
                    return true;
                else{
                    this.showErrorDialog(this.$refs.text.messagesToDisplay[0]);
                    return false;
                }
            },
			onConfirm() {
                if(this.validateForm()){
                    this.removeNoExistMention();
                    this.edit_noteData.mentioned = this.passMentionItems;
                    NoteDataService.editNote(this.edit_noteData)
                    .then(() => {
                        this.noteData.content = this.edit_noteData.content;
                        this.$emit('emitEditNoteDialog', true);
                    });
                }
			},
			onCancel() {
				this.$emit('emitEditNoteDialog', false);
			},
            onSelectd(item){
                this.mentionItems.push(item);
            },
            removeNoExistMention(){
                for(let index = 0;index<this.mentionItems.length;index++){
                    let checkString = this.mentionItems[index].text.replace("@","@</span>");
                    if(this.edit_noteData.content.includes(checkString)){
                        let mention = {};
                        mention.id = this.mentionItems[index].id;
                        if(mention.id == "all"){
                            mention.class_name = "All";
                        }else{
                            mention.class_name = "User";
                        }
                        this.passMentionItems.push(mention);
                    }
                }
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
		}
	});
</script>

